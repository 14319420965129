





















































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import IconCOD from '@/app/ui/assets/icon_cod.vue'
import IconDFOD from '@/app/ui/assets/icon_dfod.vue'
import Badge from '@/app/ui/components/Badge/index.vue'
import {
  IHeaderCell,
  ITableCell,
} from '@/data/infrastructures/misc/interfaces/cod'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import RadioButton from '@/app/ui/components/RadioButton/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import { validationMixin } from 'vuelidate'
import { Validations } from 'vuelidate-property-decorators'
import {
  maxLength,
  minLength,
  required,
  requiredIf,
} from 'vuelidate/lib/validators'
import {
  EnumCODPaymentStatus,
  EnumCODType,
} from '@/app/infrastructures/misc/Constants/cod'
import ModalConfirm from '../../components/Modals/ModalConfirm/index.vue'
import ImageViewer from '@/app/ui/components/ImageViewer/index.vue'
import controller from '@/app/ui/controllers/CODController'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import {
  CODPaymentCourierInfo,
  CODPaymentDetail,
  CODPaymentRecipientInfo,
} from '@/domain/entities/COD'
import { Utils } from '@/app/infrastructures/misc'
import { UpdateCODPaymentStatusRequest } from '@/data/payload/api/CODRequest'

interface IValidation {
  [key: string]: unknown
}

@Component({
  mixins: [validationMixin],
  components: {
    Badge,
    DataTableV2,
    RadioButton,
    TextInput,
    Button,
    ModalConfirm,
    ImageViewer,
    LoadingOverlay,
    IconCOD,
    IconDFOD,
  },
})
export default class CODPaymentDetailPage extends Vue {
  controller = controller

  enumCODPaymentStatus = EnumCODPaymentStatus
  enumCODType = EnumCODType

  form = {
    validation: '',
    note: '',
  }

  showProofPhoto = false
  indexOfProofPhoto = 0
  proofPhoto: string[] = []
  isCOD = false

  headers = [
    this.headerCellMapper('Persetujuan Oleh', '50%'),
    this.headerCellMapper('Tanggal Persetujuan', '50%'),
  ]

  isModalSubmitVisible = false
  detailData = {
    podDate: '-',
    codAmount: '-',
    stt: '-',
    historyId: '-',
    codFee: '-',
    paymentMethod: '-',
    recipientInfo: new CODPaymentRecipientInfo(),
    courierInfo: new CODPaymentCourierInfo(),
    status: this.formatStatus(this.enumCODPaymentStatus.WAITING),
    invalidReason: '',
    isWaiting: false,
  }
  isFilled = false

  approvalListData: ITableCell[][] = []

  created(): void {
    this.fetchDetail()
  }

  @Validations()
  validations(): IValidation {
    return {
      form: {
        validation: { required },
        note: {
          required: requiredIf((field: { validation: string }) => {
            return field.validation === this.enumCODPaymentStatus.INVALID
          }),
          minLength: minLength(3),
          maxLength: maxLength(100),
        },
      },
    }
  }

  get validationText(): string {
    return this.form.validation === this.enumCODPaymentStatus.VALID
      ? 'Sesuai'
      : 'Tidak Sesuai'
  }

  private fetchDetail(): void {
    const packageId: string = this.$route.params.packageId

    this.controller.getCODPayment(packageId)
  }

  public onSubmit(): void {
    const payload = new UpdateCODPaymentStatusRequest(
      this.form.validation === this.enumCODPaymentStatus.VALID ? 1 : 2,
      this.form.note
    )

    const packageId: string = this.$route.params.packageId

    this.controller.validateCODPaymentStatus({
      packageId: packageId,
      payload: payload,
    })

    this.isModalSubmitVisible = false
  }

  private formatPaymentType(type: string): string {
    if (type === 'CASH') {
      return 'Cash (Tunai)'
    } else {
      return 'QRIS (Non-Tunai)'
    }
  }

  public formatStatus(status: string): { type: string; text: string } {
    switch (status) {
      case EnumCODPaymentStatus.WAITING:
        return {
          text: 'Menunggu',
          type: 'warning',
        }
      case EnumCODPaymentStatus.VALID:
        return {
          text: 'Sesuai',
          type: 'success',
        }

      default:
        return {
          text: 'Tidak Sesuai',
          type: 'error-1',
        }
    }
  }

  public onShowProofPhoto(index: number): void {
    this.showProofPhoto = true
    this.indexOfProofPhoto = index
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string
  ): ITableCell {
    return {
      value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
      },
    }
  }

  @Watch('controller.codPaymentDetailData')
  onCODPaymentDetailDataChanged(data: CODPaymentDetail): void {
    this.detailData = {
      ...this.detailData,
      codAmount: `Rp${Utils.currencyDigit(<number>data.codAmount)}`,
      historyId: <string>data.historyId?.toString(),
      paymentMethod: <string>this.formatPaymentType(<string>data.paymentMethod),
      podDate: Utils.formatTimeZone(
        Utils.formatDateWithIDLocale(
          <string>data.podDate,
          'dddd, DD MMM YYYY, pukul HH.mm Z'
        )
      ),
      stt: <string>data.sttNumber,
      courierInfo: <CODPaymentCourierInfo>data.courierInfo,
      recipientInfo: <CODPaymentRecipientInfo>data.recipientInfo,
      status: this.formatStatus(<string>data.statusText),
      codFee: data.codFee
        ? `Rp${Utils.currencyDigit(<number>data.codFee)}`
        : '-',
      invalidReason: <string>data.invalidReason,
      isWaiting: data.status === 0,
    }

    this.form.note = <string>data.invalidReason
    if (data.statusText !== this.enumCODPaymentStatus.WAITING) {
      this.form.validation = <string>data.statusText
    }
    this.isFilled = data.statusText !== this.enumCODPaymentStatus.WAITING
    this.proofPhoto = <string[]>data.photoProofs
    this.isCOD = <string>data.codType === this.enumCODType.COD

    if (data.approvalAt && data.approvalBy) {
      this.approvalListData = [
        [
          this.tableCellMapper(<string>data.approvalBy, '50%'),
          this.tableCellMapper(
            Utils.formatTimeZone(
              Utils.formatDateWithIDLocale(
                <string>data.approvalAt,
                'dddd, DD MMM YYYY, HH.mm Z'
              )
            ),
            '50%'
          ),
        ],
      ]
    }
  }

  @Watch('controller.isValidateCODPaymentSuccess')
  onIsValidateCODPaymentSuccessChanged(data: boolean): void {
    if (data) {
      this.fetchDetail()
      this.controller.setIsValidateCODPaymentSuccess(false)
    }
  }
}
